import React, { useState, useRef, useEffect, LegacyRef } from 'react'
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Lottie } from '@crello/react-lottie'
import animationPlay from '../../../../sea-site-theme/src/animations/play-pause-circle.json'
import {
	DolphinVideoFeature,
	DolphinVideoHolder,
	DolphinVideoContainer,
	PlayPauseBtn,
	VideoHook,
	Heading3,
	DolphinDiffParagraph,
	DolphinFeatureHolder,
	DolphinFeatureContainer,
	Feature,
	FeatureImg,
	FeatureName,
	FeatureText,
	VideoOverlay,
	ClimateCareContainer,
	TextsContainer,
	ClimateCareImage,
	// LearnMore,
	ThumbnailContainer,
} from './styles'
import useWindowResize from '../../../../sea-site-theme/src/hooks/useWindowResize'
import { VidBanner, DolphinFeature } from './dolphin-types'

interface Props {
	data: VidBanner
	dolphinfeatures: DolphinFeature
}

const DolphinDifference = ({ data, dolphinfeatures }: Props): JSX.Element => {
	const { hook, videoBanner, description, image, videoThumbnail } = data
	const dolphinOptions = {
		renderNode: {
			[BLOCKS.HEADING_3]: function Head3(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <Heading3>{children}</Heading3>
			},
			[BLOCKS.PARAGRAPH]: function Paragraph(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <DolphinDiffParagraph>{children}</DolphinDiffParagraph>
			},
		},
	}

	const FeatureOptions = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: function Paragraph(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <FeatureText>{children}</FeatureText>
			},
		},
	}

	const [control, setControl] = useState(false)
	const [coordinates, setCoordinates] = useState(false)
	const [hide, setHide] = useState(false)
	const [isPlaying, setIsPlaying] = useState(true)
	// const videoElement = document.createElement('video')
	const play = useRef<HTMLVideoElement | null>(null)
	const playVidHandler = () => {
		setControl((prev) => !prev)
		setIsPlaying((prev) => !prev)
		if (control === false) {
			play != null || play != undefined ? play.current?.play() : null
		} else {
			play != null || play != undefined ? play.current?.pause() : null
		}
	}
	const detectMove = () => {
		setCoordinates(true)
	}
	useEffect(() => {
		const timeout = setTimeout(() => {
			setCoordinates(false)
		}, 3000)

		return () => {
			clearTimeout(timeout)
		}
	}, [coordinates, control])

	useEffect(() => {
		if (coordinates === false && control === true) {
			setHide(true)
		} else {
			setHide(false)
		}
	}, [coordinates, control])

	const { width } = useWindowResize()

	return (
		<DolphinVideoFeature>
			<DolphinVideoHolder>
				<video ref={(play as unknown) as LegacyRef<HTMLVideoElement> | undefined} width="100%" height="100%" loop>
					<source src={`${videoBanner?.file?.url}`} type="video/mp4"></source>
					<track kind="captions"></track>
				</video>
				<DolphinVideoContainer
					onMouseMove={() => {
						detectMove()
					}}
					bg={control ? '' : '#b8c2cc'}
					bgImage={width <= 524 ? (control === false ? videoThumbnail.file.url : false) : false}
				>
					<>
						<VideoOverlay>
							<ClimateCareContainer>
								{control === false ? (
									<ClimateCareImage src={image?.file?.url} alt={image?.description}></ClimateCareImage>
								) : (
									''
								)}
							</ClimateCareContainer>
							<TextsContainer>
								<ThumbnailContainer>
									<img
										src={videoThumbnail.file.url}
										width="450"
										alt={videoThumbnail.description}
										style={{ display: control === false ? (width <= 524 ? `none` : `block`) : `none` }}
									/>
									<PlayPauseBtn onClick={playVidHandler} fade={hide ? 0 : 1}>
										<Lottie
											playingState={'playing'}
											speed={0.5}
											direction={isPlaying ? 1 : -1}
											width="65px"
											height="65px"
											className="lottie-container play-pause"
											config={{
												animationData: animationPlay,
												loop: false,
												autoplay: false,
												renderer: 'svg',
											}}
											aria-hidden
										/>
									</PlayPauseBtn>
								</ThumbnailContainer>
								{control === false ? (
									<div>
										<VideoHook>{hook}</VideoHook>
										{documentToReactComponents(JSON.parse(description.raw), dolphinOptions)}
									</div>
								) : (
									''
								)}
							</TextsContainer>
						</VideoOverlay>
					</>
				</DolphinVideoContainer>
			</DolphinVideoHolder>

			<DolphinFeatureHolder>
				<DolphinFeatureContainer>
					{dolphinfeatures?.items.length > 0
						? dolphinfeatures.items.map((item, i: number) => {
								return (
									<Feature key={i}>
										<FeatureImg src={item.image.file.url} alt={item.image.description} />
										<FeatureName>{item.name}</FeatureName>
										{documentToReactComponents(JSON.parse(item.body.raw), FeatureOptions)}
									</Feature>
								)
						  })
						: ''}
				</DolphinFeatureContainer>
				{/* <LearnMore to={'/'}>Learn More</LearnMore> */}
			</DolphinFeatureHolder>
		</DolphinVideoFeature>
	)
}

export default DolphinDifference
