import React from 'react'
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ReviewsSection, ReviewContainer, Review, ReviewTitle, ReviewParagraph } from './styles'
import { ReviewProps } from '../../pages/dolphin'

interface Props {
	data: ReviewProps
}

const options = {
	renderNode: {
		[BLOCKS.PARAGRAPH]: function Paragraph(
			node: Block | Inline | Text,
			children: React.ReactChild | undefined | null | React.ReactFragment
		) {
			return <ReviewParagraph>{children}</ReviewParagraph>
		},
	},
}

const Reviews = ({ data }: Props): JSX.Element => {
	return (
		<ReviewsSection>
			<ReviewContainer>
				{data?.items.map((item, i: number) => {
					return (
						<Review key={i}>
							{documentToReactComponents(JSON.parse(item.body.raw), options)}
							<ReviewTitle>{item.name}</ReviewTitle>
						</Review>
					)
				})}
			</ReviewContainer>
		</ReviewsSection>
	)
}

export default Reviews
