import React from 'react'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { BLOCKS, MARKS, Block, Inline, Text } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Img, { FluidObject } from 'gatsby-image'
import SLine from '../../../../sea-site-theme/src/svg/s-line'
import MLine from '../../../../sea-site-theme/src/svg/m-line'
import XLine from '../../../../sea-site-theme/src/svg/x-line'
import Eline from '../../../../sea-site-theme/src/svg/e-line'
import useSlider from '../../../../sea-site-theme/src/hooks/useSlider'
import { DolphinLine } from '../../pages/dolphin'

interface Gram {
	name: string
	url: string
	body: {
		raw: string
	}
	image: {
		fluid: FluidObject
		description: string
		title: string
	}
}

interface DolphinLineProps {
	dolphinlines: DolphinLine
}

import {
	Section,
	Title,
	Subtitle,
	Item,
	Content,
	ItemSubTitle,
	Paragraph,
	Italic,
	UnorderedList,
	ItemButton,
	ItemImage,
	FeaturedButtonHolder,
	FeaturedContainer,
	FeaturedHolder,
	Previous,
	Next,
	ViewMore,
	ViewMoreBtn,
	Bold,
} from './styles'

const Mline = styled(MLine)`
	${tw`mb-3`}
`
const Sline = styled(SLine)`
	${tw`mb-3`}
`
const Xline = styled(XLine)`
	${tw`mb-3`}
`
const DolphinLines = ({ dolphinlines }: DolphinLineProps): JSX.Element => {
	const { title, subtitle, items } = dolphinlines
	const options = {
		renderMark: {
			[MARKS.BOLD]: function BoldText(text: React.ReactChild | React.ReactFragment | null | undefined) {
				return <Bold>{text}</Bold>
			},
			[MARKS.ITALIC]: function ItalicText(text: React.ReactChild | React.ReactFragment | null | undefined) {
				return <Italic>{text}</Italic>
			},
		},
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: function EmbeddedAsset(node: Block | Inline | Text) {
				const { file, description } = node.data.target.fields
				return <ItemImage src={file['en-AU'].url} alt={description['en-AU']} />
			},
			[BLOCKS.PARAGRAPH]: function Parag(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <Paragraph>{children}</Paragraph>
			},
			[BLOCKS.HEADING_4]: function Heading4(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <ItemSubTitle>{children}</ItemSubTitle>
			},
			[BLOCKS.UL_LIST]: function List(
				node: Block | Inline | Text,
				children: React.ReactChild | undefined | null | React.ReactFragment
			) {
				return <UnorderedList>{children}</UnorderedList>
			},
		},
	}
	const {
		containerWidth,
		imgLen,
		gram,
		transition,
		transitionValue,
		PreviousGramHandler,
		NextGramHandler,
		arrLen,
	} = useSlider(items)

	return (
		<Section>
			<Title>{title}</Title>
			<Subtitle>{subtitle}</Subtitle>
			<FeaturedButtonHolder arrLen={arrLen}>
				<FeaturedContainer>
					<FeaturedHolder
						ref={containerWidth}
						style={{
							transform: `translate3d(${transitionValue}px, 0, 0`,
							transition: transition ? 'all 0.5s linear' : '',
						}}
					>
						{gram.map((item: Gram, index: number) => {
							return (
								<Item key={index} ref={imgLen as React.LegacyRef<HTMLDivElement> | undefined} arrLen={arrLen}>
									<Link to={'/' + item.url}>
										<Img fluid={item.image.fluid} alt={item.image.description} className="item-image" />
									</Link>
									<Content>
										{item.name === 'M Line' ? (
											<Mline width="100" height="40" />
										) : item.name === 'S Line' ? (
											<Sline width="100" height="40" />
										) : item.name === 'X Line' ? (
											<Xline width="100" height="40" />
										) : item.name === 'E Line' ? (
											<Eline width="100" height="40" />
										) : (
											''
										)}
										{item.body ? documentToReactComponents(JSON.parse(item.body.raw), options) : ''}
										<Link to={'/' + item.url}>
											<ItemButton>Learn More</ItemButton>
										</Link>
									</Content>
								</Item>
							)
						})}
					</FeaturedHolder>
				</FeaturedContainer>
				<Previous disabled={transition} onClick={() => PreviousGramHandler()}>
					<ChevronLeft color="#58C8DF" width="70" height="70" />
				</Previous>
				<Next disabled={transition} onClick={() => NextGramHandler()}>
					<ChevronRight color="#58C8DF" width="70" height="70" />
				</Next>
				{/* <ViewMore>
					<ViewMoreBtn to={`/shop/?brand=Dolphin`}>View All Models</ViewMoreBtn>
				</ViewMore> */}
			</FeaturedButtonHolder>
		</Section>
	)
}

export default DolphinLines
