import { useEffect, useState, useCallback, useRef, MutableRefObject } from 'react'
import isEqual from 'lodash.isequal'
import useWindowResize from './useWindowResize'
import { FluidObject } from 'gatsby-image'
import React from 'react'
// import { scrapingInstagramPosts } from 'gatsby-source-instagram/instagram'

interface Items {
	name: string
	url: string
	body: {
		raw: string
	}
	image: {
		title: string
		description: string
		fluid: FluidObject
	}
}

interface UseSlider {
	containerWidth: React.LegacyRef<HTMLDivElement> | undefined
	imgLen: MutableRefObject<HTMLDivElement | undefined>
	gram: Items[]
	arr: number[]
	transition: boolean
	transitionValue: number
	PreviousGramHandler: () => void
	NextGramHandler: () => void
	arrLen: number
}

const useSlier = (items: Items[], instagram?: string): UseSlider => {
	const [gram, setGram] = useState<Items[]>([])
	const [containerLen, setContainerLen] = useState(0)
	const [arrLen, setArrLen] = useState<number>(0)
	const [arr, setArr] = useState<number[]>([])
	const [transitionValue, setTransitionValue] = useState<number>(0)
	const [transition, setTransition] = useState(false)
	const imgLen = useRef<HTMLDivElement | undefined>()
	const { width } = useWindowResize()
	const widths = {
		five: 5,
		three: 3,
		two: 2,
		one: 1,
	}
	// async function getInstagramPosts(options) {
	//   return await scrapingInstagramPosts(options)
	// }

	useEffect(() => {
		if (instagram === undefined) {
			const instaOne = [...items]
			const instaTwo = [...items]
			const mergedInsta = instaOne.concat(instaTwo)
			setGram(mergedInsta)
		} else {
			// getInstagramPosts(instagram).then(res => {
			//   const instaOne = [...res]
			//   const instaTwo = [...res]
			//   const mergedInsta = instaOne.concat(instaTwo)
			//   setGram(mergedInsta)
			// })
		}
	}, [])

	useEffect(() => {
		if (width >= 768 && width < 1280) {
			if (instagram != null || instagram != undefined) {
				if (items.length < 3) {
					setArrLen(items.length)
				} else {
					setArrLen(widths.three)
				}
			} else {
				if (items.length < 3) {
					setArrLen(items.length)
				} else {
					setArrLen(widths.two)
				}
			}
		} else if (width > 639 && width < 768) {
			if (items.length < 3) {
				setArrLen(items.length)
			} else {
				setArrLen(widths.two)
			}
		} else if (width > 1 && width < 640) {
			if (items.length < 3) {
				setArrLen(items.length)
			} else {
				setArrLen(widths.one)
			}
		} else {
			if ((items === null || items === undefined) && (instagram != null || instagram != undefined)) {
				setArrLen(widths.five)
			} else {
				if (items.length < 3) {
					setArrLen(items.length)
				} else {
					setArrLen(widths.three)
				}
			}
		}
	}, [arrLen, width])

	useEffect(() => {
		if (gram.length != 0) {
			if (arrLen * 2 === gram.length) {
				const instaOne = [...items]
				const instaTwo = [...items]
				const mergedInsta = instaOne.concat(instaTwo).concat(instaOne).concat(instaTwo)
				setGram(mergedInsta)
			} else {
				return
			}
		}
	}, [arrLen])

	const containerWidth = useCallback(
		(node: HTMLElement | null) => {
			if (node !== null) {
				setContainerLen(node.getBoundingClientRect().width)
			}
		},
		[arrLen]
	)

	const translate = (((containerLen / arrLen) * gram.length) / 2) * -1
	const translateToLast = (((gram.length / 2 - arrLen) * containerLen) / arrLen) * -1
	const start = Array.from(Array(gram.length).keys()).slice(gram.length / 2, gram.length / 2 + arrLen)
	const end = Array.from(Array(Math.ceil(gram.length / 2)).keys()).slice(-arrLen)
	const slicedGramStart = Array.from(Array(gram.length).keys()).slice(0, arrLen)
	const slicedGramEnd = Array.from(Array(gram.length).keys()).slice(-arrLen)

	useEffect(() => {
		setTransitionValue(translate)
	}, [translate])

	useEffect(() => {
		const activeArr = []
		const halfArr = gram.length / 2
		for (let index = 0; index < arrLen; index++) {
			activeArr.push(halfArr + index)
		}
		setArr(activeArr)
	}, [arrLen, gram])

	const PreviousGramHandler = () => {
		const len = imgLen.current != null || imgLen.current != undefined ? imgLen.current.getBoundingClientRect().width : 0
		const prevPos = transitionValue + len
		setTransition(true)
		const newArr = arr.map((x): number => {
			const check = (value: number) => {
				if (value <= 0) {
					return (value = gram.length - 1)
				} else {
					return value - 1
				}
			}
			if (x <= gram.length - 1) {
				return check(x)
			}
			return check(x)
		})
		const x = isEqual(newArr, slicedGramStart)
		if (x === true) {
			setTimeout(() => {
				return setTransitionValue(translate), setArr(start)
			}, 500)
			setTimeout(() => {
				setTransition(false)
			}, 500)
		} else {
			setTimeout(() => {
				setTransition(false)
			}, 500)
		}
		setTransitionValue(prevPos)
		setArr(newArr)
	}

	const NextGramHandler = () => {
		const len = imgLen.current != null ? imgLen.current.getBoundingClientRect().width : 0
		const nextPos = transitionValue - len
		setTransition(true)
		const newArr = arr.map((x) => {
			const check = (value: number) => {
				if (value > arr.length - 1) {
					return (value = 0)
				} else {
					return value + 1
				}
			}
			if (x >= gram.length - 1) {
				return check(x + 1)
			} else {
				return x + 1
			}
		})
		const x = isEqual(newArr, slicedGramEnd)
		if (x === true) {
			setTimeout(() => {
				return setTransitionValue(translateToLast), setArr(end)
			}, 500)
			setTimeout(() => {
				setTransition(false)
			}, 500)
		} else {
			setTimeout(() => {
				setTransition(false)
			}, 500)
		}
		setTransitionValue(nextPos)
		setArr(newArr)
	}

	return {
		containerWidth,
		imgLen,
		gram,
		arr,
		transition,
		transitionValue,
		PreviousGramHandler,
		NextGramHandler,
		arrLen,
	}
}

export default useSlier
