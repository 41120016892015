import React from 'react'
import Layout from '../../../sea-site-theme/src/components/Layout'
import SEO from '../../../sea-site-theme/src/components/SEO'
import Hero from '../components/dolphin/hero'
import DolphinLines from '../components/dolphin/dolphin-lines'
import WhichModel from '../../../sea-site-theme/src/components/which-model'
import Reviews from '../components/dolphin/reviews'
import DolphinDifference from '../components/dolphin/dolphin-difference'
import TopModels from '../components/dolphin/top-models'
import { graphql } from 'gatsby'
import { DolphinBanner, DolphinFeature, VidBanner, ItemsTypes } from '../components/dolphin/dolphin-types'
import { FluidObject } from 'gatsby-image'

interface Dolphin {
	node: {
		banner: DolphinBanner
		media: {
			description: string
			file: {
				url: string
			}
		}
		node_locale: string
		seoTitle: string
		slug: string
		seoDescription: string
		seoKeywords: string[]
	}
}

export interface DolphinLine {
	title: string
	subtitle: string
	node_locale: string
	items: {
		name: string
		url: string
		body: {
			raw: string
		}
		image: {
			title: string
			description: string
			fluid: FluidObject
		}
	}[]
}

// used in top-models.tsx
export interface ProductImage extends ItemsTypes {
	images: {
		description: string
		fluid: {
			src: string
			srcWebp: string
		}
	}[]
}

interface WhichModelProps {
	backgroundImage: {
		file: {
			url: string
		}
	}
	description: {
		raw: string
	}
	hook: string
	mainActionText: string
	mainActionUrl: string
	node_locale: string
}

export interface ReviewProps {
	title: string
	node_locale: string
	items: {
		name: string
		node_locale: string
		body: {
			raw: string
		}
	}[]
}

interface HeroImageSrc {
	edges: {
		node: {
			backgroundImage: {
				fluid: {
					src: string
					srcWebp: string
				}
			}
		}
	}[]
}

interface Props {
	data: {
		dolphin: {
			edges: Dolphin[]
		}
		dolphinLines: {
			edges: {
				node: DolphinLine
			}[]
		}
		videobanner: {
			edges: {
				node: VidBanner
			}[]
		}
		dolphinfeature: {
			edges: {
				node: DolphinFeature
			}[]
		}
		productImage: {
			edges: {
				node: ProductImage
			}[]
		}
		whichModel: {
			edges: {
				node: WhichModelProps
			}[]
		}
		reviews: {
			edges: {
				node: ReviewProps
			}[]
		}
		xxsImage: HeroImageSrc
		xsImage: HeroImageSrc
		smImage: HeroImageSrc
		mdImage: HeroImageSrc
		lgImage: HeroImageSrc
	}
}

const Dolphin = ({
	data: {
		dolphin,
		xxsImage,
		xsImage,
		smImage,
		mdImage,
		lgImage,
		whichModel,
		reviews,
		videobanner,
		dolphinfeature,
		dolphinLines,
		productImage,
	},
}: Props): JSX.Element => {
	const [{ node }] = dolphin.edges
	const [{ node: whichmodel }] = whichModel.edges
	const [{ node: review }] = reviews.edges
	const [{ node: vidbanner }] = videobanner.edges
	const [{ node: dolphinfeatures }] = dolphinfeature.edges
	const [{ node: dolphinlines }] = dolphinLines.edges
	const [{ node: xxs }] = xxsImage.edges
	const [{ node: xs }] = xsImage.edges
	const [{ node: sm }] = smImage.edges
	const [{ node: md }] = mdImage.edges
	const [{ node: lg }] = lgImage.edges
	const topmodelimages = productImage.edges

	const {
		seoTitle,
		seoKeywords,
		seoDescription,
		media: heroLineUrl,
		banner: {
			image,
			mainActionUrl,
			mainActionText,
			secondActionText,
			secondActionUrl,
			thirdActionText,
			thirdActionUrl,
			description,
		},
	} = node

	const sources = [
		{
			...lg.backgroundImage.fluid,
			srcSet: `${lg.backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${lg.backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1350px)`,
		},
		{
			...md.backgroundImage.fluid,
			srcSet: `${md.backgroundImage.fluid.src} 1024w`,
			srcSetWebp: `${md.backgroundImage.fluid.srcWebp} 1024w`,
			media: `(min-width: 1024px) and (max-width: 1349px)`,
		},
		{
			...sm.backgroundImage.fluid,
			srcSet: `${sm.backgroundImage.fluid.src} 768w`,
			srcSetWebp: `${sm.backgroundImage.fluid.srcWebp} 768w`,
			media: `(min-width: 768px) and (max-width: 1023px)`,
		},
		{
			...xs.backgroundImage.fluid,
			srcSet: `${xs.backgroundImage.fluid.src} 525w`,
			srcSetWebp: `${xs.backgroundImage.fluid.srcWebp} 525w`,
			media: `(min-width: 525px) and (max-width: 767px)`,
		},
		{
			...xxs.backgroundImage.fluid,
			srcSet: `${xxs.backgroundImage.fluid.src} 410w`,
			srcSetWebp: `${xxs.backgroundImage.fluid.srcWebp} 410w`,
			media: `(max-width: 524px)`,
		},
	]

	return (
		<Layout>
			<SEO title={seoTitle} keywords={seoKeywords} description={seoDescription} />

			<Hero
				{...{
					mainActionText,
					mainActionUrl,
					secondActionText,
					secondActionUrl,
					thirdActionText,
					thirdActionUrl,
					description,
					heroLineUrl,
					sources,
					image,
				}}
			/>
			<DolphinLines dolphinlines={dolphinlines} />
			<Reviews data={review} />
			<DolphinDifference data={vidbanner} dolphinfeatures={dolphinfeatures} />
			<TopModels modelimages={topmodelimages} />
			<WhichModel data={whichmodel} />
		</Layout>
	)
}

export const dolphinQuery = graphql`
	query dolphinQuery {
		dolphin: allContentfulPage(filter: { contentful_id: { eq: "dKWr9uOdHlAM5tnoxUNsr" } }) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					slug
					node_locale
					media {
						description
						file {
							url
						}
					}
					banner {
						image {
							description
							file {
								url
							}
						}
						mainActionUrl
						mainActionText
						secondActionUrl
						secondActionText
						thirdActionUrl
						thirdActionText
						description {
							raw
						}
					}
				}
			}
		}
		whichModel: allContentfulBanner(filter: { contentful_id: { eq: "5UBkM4QSv0bHOcKjyMBmw1" } }) {
			edges {
				node {
					hook
					node_locale
					mainActionText
					mainActionUrl
					description {
						raw
					}
					backgroundImage {
						file {
							url
						}
					}
				}
			}
		}
		reviews: allContentfulFeaturedItems(filter: { contentful_id: { eq: "7eklcAUx1dnVwK62d8gbVw" } }) {
			edges {
				node {
					title
					node_locale
					items {
						node_locale
						name
						body {
							raw
						}
					}
				}
			}
		}
		videobanner: allContentfulBanner(filter: { contentful_id: { eq: "bsDAxl4WMF5PAoHTBe4V7" } }) {
			edges {
				node {
					hook
					node_locale
					videoThumbnail {
						file {
							url
						}
						description
					}
					image {
						description
						file {
							url
						}
					}
					videoBanner {
						file {
							url
						}
					}
					description {
						raw
					}
				}
			}
		}
		dolphinfeature: allContentfulFeaturedItems(filter: { contentful_id: { eq: "5ZEZgO98FOLRu8g0NUEETq" } }) {
			edges {
				node {
					node_locale
					items {
						node_locale
						image {
							file {
								url
							}
							description
							title
						}
						body {
							raw
						}
						name
					}
				}
			}
		}
		findDealer: allContentfulBanner(filter: { hook: { eq: "Find a dealer" }, identifier: { eq: "dolphin" } }) {
			edges {
				node {
					mainActionText
					mainActionUrl
					secondActionText
					secondActionUrl
					hook
					node_locale
					backgroundImage {
						file {
							url
						}
					}
					description {
						raw
					}
				}
			}
		}
		xxsImage: allContentfulPage(filter: { contentful_id: { eq: "dKWr9uOdHlAM5tnoxUNsr" } }) {
			edges {
				node {
					backgroundImage {
						fluid(maxWidth: 524, maxHeight: 400, quality: 60, cropFocus: CENTER, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		xsImage: allContentfulPage(filter: { contentful_id: { eq: "dKWr9uOdHlAM5tnoxUNsr" } }) {
			edges {
				node {
					backgroundImage {
						fluid(maxWidth: 767, maxHeight: 500, quality: 60, cropFocus: RIGHT, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		smImage: allContentfulPage(filter: { contentful_id: { eq: "dKWr9uOdHlAM5tnoxUNsr" } }) {
			edges {
				node {
					backgroundImage {
						fluid(maxWidth: 1023, maxHeight: 600, quality: 60, cropFocus: CENTER, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		mdImage: allContentfulPage(filter: { contentful_id: { eq: "dKWr9uOdHlAM5tnoxUNsr" } }) {
			edges {
				node {
					backgroundImage {
						fluid(maxWidth: 1349, maxHeight: 700, quality: 60, cropFocus: CENTER, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		lgImage: allContentfulPage(filter: { contentful_id: { eq: "dKWr9uOdHlAM5tnoxUNsr" } }) {
			edges {
				node {
					backgroundImage {
						fluid(maxWidth: 1920, maxHeight: 700, quality: 60, cropFocus: BOTTOM, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		dolphinLines: allContentfulFeaturedItems(filter: { contentful_id: { eq: "1vuxSEZXPjF4N1WpMgxZmj" } }) {
			edges {
				node {
					node_locale
					title
					subtitle
					items {
						name
						body {
							raw
						}
						url
						image {
							title
							description
							fluid(maxWidth: 500, maxHeight: 500, quality: 60) {
								...GatsbyContentfulFluid
							}
						}
					}
				}
			}
		}
		productImage: allContentfulProduct(
			filter: { contentful_id: { in: ["1ojRCuS6Jr8phiXxHauzfn", "1T2Jd2wY1BNeFoctpLRPC9"] } }
		) {
			edges {
				node {
					node_locale
					seoTitle
					seoDescription
					slug
					sku
					price
					model
					brand
					active
					series
					categories {
						title: seoTitle
						slug
					}
					shippingClass
					images {
						fluid(maxWidth: 600, maxHeight: 400, quality: 60) {
							src
							srcWebp
						}
						description
					}
				}
			}
		}
		elitedealers: allContentfulBanner(filter: { contentful_id: { eq: "3kDhudBySXImZRyo8t6CO6" } }) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(maxWidth: 1920, maxHeight: 1400, quality: 60, cropFocus: RIGHT, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
					description {
						raw
					}
				}
			}
		}
		elitelg: allContentfulBanner(filter: { contentful_id: { eq: "3kDhudBySXImZRyo8t6CO6" } }) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(maxWidth: 1400, maxHeight: 1050, quality: 60, cropFocus: RIGHT, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
				}
			}
		}
		elitemd: allContentfulBanner(filter: { contentful_id: { eq: "3kDhudBySXImZRyo8t6CO6" } }) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(maxWidth: 1280, maxHeight: 900, quality: 60, cropFocus: RIGHT, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
				}
			}
		}
		elitesm: allContentfulBanner(filter: { contentful_id: { eq: "3kDhudBySXImZRyo8t6CO6" } }) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(maxWidth: 1024, maxHeight: 800, quality: 60, cropFocus: RIGHT, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
				}
			}
		}
		elitemxs: allContentfulBanner(filter: { contentful_id: { eq: "3kDhudBySXImZRyo8t6CO6" } }) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(maxWidth: 768, maxHeight: 700, quality: 60, cropFocus: RIGHT, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
				}
			}
		}
		elitexs: allContentfulBanner(filter: { contentful_id: { eq: "3kDhudBySXImZRyo8t6CO6" } }) {
			edges {
				node {
					node_locale
					backgroundImage {
						fluid(maxWidth: 524, maxHeight: 600, quality: 60, cropFocus: RIGHT, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
				}
			}
		}
	}
`

export default Dolphin
