import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useQuery, gql } from '@apollo/client'
import { Client } from '../../../../sea-site-theme/src/apollo/client'
import Spinner from '../../../../sea-site-theme/src/components/utils/spinner'
import isEmpty from 'lodash.isempty'
import { onProductClick } from '../../../../sea-site-theme/src/components/utils/analytics'
import { ItemsTypes } from './dolphin-types'
import { ProductImage } from '../../pages/dolphin'

import {
	TopModelsSection,
	TopModelsContainer,
	ItemsContainer,
	ItemContainer,
	ItemInnerContainer,
	ItemImageContainer,
	TopModelItemImage,
	DetailsContainer,
	ProductName,
	AddToCartContainer,
	Price,
	TopModelFeature,
	ItemDetails,
	Continue,
	NullText,
	Button,
	ViewAll,
	ViewMore,
} from './styles'

interface Items extends ItemsTypes {
	featuresCollection: {
		items: {
			title: string
		}[]
	}
}

interface ApolloQueryData {
	productCollection: {
		items: Items[]
	}
}

interface QueryVars {
	models: string[]
}

interface ModelImage {
	modelimages: {
		node: ItemsTypes & ProductImage
	}[]
}

const TopModels = ({ modelimages }: ModelImage): JSX.Element => {
	const isBrowser = typeof window !== 'undefined'
	const [items, setItems] = useState<Items[]>([])
	useEffect(() => {
		/**
		 * Google Analytics - Tag Manager - Enhanced Ecommerce
		 * Measuring product impressions for every search result change
		 * */
		const floatPrice = (price: number) =>
			price >= 1
				? typeof price != 'number'
					? parseFloat(price).toFixed(2)
					: price.toFixed(2)
				: parseFloat('0').toFixed(2)
		const impressions =
			items.length >= 1 &&
			items.map((item, index) => {
				return {
					name: item.seoTitle, // the name of the product, including Title and Subtitle
					id: item.sku, // product serial number
					price: Number(floatPrice(item.price)), // actual price per unit or in case of fixed price discounts the discounted price
					brand: item.brand, // product brand
					category: [item.categories && item.categories.title].toString(), // Dolphin domestic, Dolphin commercial, mineral swim, ozone swim
					list: 'Category List', // the type of list for the current product impression )e.g. Category List, Search Results etc.)
					position: index + 1, // the position of the product within the list
				}
			})
		if (isBrowser && window.dataLayer && items.length >= 1) {
			window.dataLayer.push({
				event: 'productImpression',
				ecommerce: {
					currencyCode: 'AUD',
					impressions,
				},
			})
		}
	}, [items])

	const { loading, error, data } = useQuery<ApolloQueryData, QueryVars>(APOLLO_DOLPHIN_QUERY, {
		variables: { models: ['M 600', 'S 300i'] },
		client: Client,
	})

	useEffect(() => {
		if (data) {
			setItems(data.productCollection.items)
		}
	}, [data])
	return (
		<TopModelsSection>
			<TopModelsContainer>
				<ItemsContainer>
					{error ? <NullText>Something is wrong. Please Try Again!</NullText> : ''}
					{loading ? (
						<Spinner />
					) : items.length > 0 ? (
						items.map((item, i) => {
							if (!isEmpty(item)) {
								const title = item.seoTitle
								const titleSplit = title.split(' ')
								const dolphin = titleSplit.find((ttl) => ttl === 'Dolphin')
								const itemName = item.seoTitle.split('Dolphin').slice(1)
								const filterImg = (img: { node: ItemsTypes & ProductImage }[]) => {
									return img.filter((x) => {
										return x.node.slug === item.slug ? x : ''
									})
								}
								const filteredByslug = filterImg(modelimages)
								return (
									<ItemContainer key={i}>
										<ItemInnerContainer>
											<ItemImageContainer
												onClick={() => {
													onProductClick(
														{
															name: item.seoTitle,
															sku: item.sku,
															price: Number(item.price * 100),
															brand: item.brand,
															categories: item.categories,
															list: 'Category List',
															position: i + 1,
														},
														'Category List',
														i,
														navigate(`/product/${item.slug}`)
													)
												}}
											>
												<TopModelItemImage>
													<source srcSet={filteredByslug[0].node.images[0].fluid.srcWebp} type="image/webp" />
													<source srcSet={filteredByslug[0].node.images[0].fluid.src} />
													<img
														src={filteredByslug[0].node.images[0].fluid.src}
														alt={filteredByslug[0].node.images[0].description}
													/>
												</TopModelItemImage>
											</ItemImageContainer>
											<DetailsContainer>
												<ProductName>
													<span>{dolphin}</span>
													{itemName}
												</ProductName>
												{/* <Price>
													{item.price.toLocaleString('en-SG', {
														style: 'currency',
														currency: 'SGD',
													})}
												</Price> */}
												{item.featuresCollection.items.map((feature, i) => {
													return <TopModelFeature key={`${feature.title}${i}`}>{feature.title}</TopModelFeature>
												})}
											</DetailsContainer>
											<AddToCartContainer>
												{/* <Button
													onClick={() => {
														addToCart({
															img: filteredByslug[0].node.images[0].fluid.src,
															slug: item.slug,
															name: item.seoTitle,
															sku: item.sku,
															price: Number(item.price * 100),
															brand: item.brand,
															category: [item.categories && item.categories.title].toString(),
															shippingClass: item.shippingClass,
															quantity: 1,
														})
														onAddToCart(
															{
																name: item.seoTitle,
																sku: item.sku,
																price: Number(item.price * 100),
																brand: item.brand,
																category: [item.categories && item.categories.title].toString(),
																shippingClass: item.shippingClass,
																list: 'Category List',
																position: i + 1,
																quantity: 1,
															},
															'Category List'
														)
														openCart()
													}}
												>
													Add to cart
												</Button> */}
												<Price>
													{item.price.toLocaleString('en-ZA', {
														style: 'currency',
														currency: 'ZAR',
														minimumFractionDigits: 2,
														maximumFractionDigits: 2
													})}
												</Price>
												<Button
													onClick={() => {
														navigate(
															`https://mydolphin.co.za/product/${item.slug}`
														)
													}}
												>
													Buy Now
												</Button>
											</AddToCartContainer>
											<ItemDetails
												onClick={() => {
													onProductClick(
														{
															name: item.seoTitle,
															sku: item.sku,
															price: Number(item.price * 100),
															brand: item.brand,
															categories: item.categories,
															list: 'Category List',
															position: i + 1,
														},
														'Category List',
														i,
														navigate(`/product/${item.slug}`)
													)
												}}
											>
												<Continue>View Product</Continue>
											</ItemDetails>
										</ItemInnerContainer>
									</ItemContainer>
								)
							}
						})
					) : (
						<NullText>No items can be found!</NullText>
					)}
				</ItemsContainer>
				{/* <ViewMore>
					<ViewAll to={`/shop/?brand=Dolphin`}>View All Models</ViewAll>
				</ViewMore> */}
			</TopModelsContainer>
		</TopModelsSection>
	)
}

const APOLLO_DOLPHIN_QUERY = gql`
	query topModelsQuery($models: [String]!) {
		productCollection(locale: "en-ZA", where: { model_in: $models }, order: [popularity_DESC]) {
			items {
				seoTitle
				seoDescription
				slug
				sku
				price
				model
				brand
				active
				series
				categories {
					title: seoTitle
					slug
				}
				shippingClass
				featuresCollection(limit: 3) {
					items {
						title
					}
				}
			}
		}
	}
`

export default TopModels
